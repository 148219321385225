<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <el-tabs v-model="activeName" class="tabs-card-l10" :before-leave="tabBeforeLeave">
                <el-tab-pane 
                    label="押金收取" 
                    name="DepositReceive" 
                    :lazy="true">
                    <DepositReceive v-if="activeName == 'DepositReceive'"></DepositReceive>
                </el-tab-pane>
                <el-tab-pane 
                    label="押金退回" 
                    name="DepositRefund" 
                    :lazy="true">
                    <DepositRefund v-if="activeName == 'DepositRefund'"></DepositRefund>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import DepositReceive from './DepositReceive'
import DepositRefund from './DepositRefund'

export default {
    name: 'Docket',
    components: {
        DepositReceive,
        DepositRefund
    },
    data() {
        let tabName;
        if (this.$route.query.type == 1) {
            tabName = 'DepositRefund';
        } else {
            tabName = 'DepositReceive';
        }
        return {
            activeName: tabName,
            userInfo: {},
        };
    },
    created () {
        this.userInfo = this.getUserInfo();
    },
    methods: {
        ...common,
        tabBeforeLeave(activeName) {
            if (activeName == 'DepositRefund') {
                this.$router.push({ path: '/cost/deposit', query: { type: 1 } });
            } else {
                this.$router.push({ path: '/cost/deposit' });
            }
            return true;
        },
    }
}
</script>

<style scoped>
</style>