<template>
    <div class="frame-content-tab">
        <div class="frame-content-tab-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-input v-model="filters.subcontractor" clearable placeholder="分包单位" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.refundStatus" clearable placeholder="退回状态" size="small">
                        <el-option label="待退回" value="1"></el-option>
                        <el-option label="已退回" value="2"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.intoStatus" clearable placeholder="入住状态" size="small">
                        <el-option label="已入住" value="1"></el-option>
                        <el-option label="已退宿" value="2"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-date-picker
                        v-model="filters.refundDate"
                        type="date"
                        placeholder="退回日期"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleExport">导出Excel</el-button>
                    </div>
                    <!-- <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div> -->
                </div>
                <div class="datatable">
                    <el-table
                        ref="curDepositRefundTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (pageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="refundStatus"
                            label="退回状态"
                            width="100">
                            <template slot-scope="scope">
                                <el-tag
                                    :type="scope.row.refundStatus === 1 ? 'success' : 'warning'"
                                    disable-transitions>{{scope.row.refundStatus === 1 ? '已退回' : '待退回'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="subcontractor"
                            label="分包单位名称"
                            width="220">
                        </el-table-column>
                        <el-table-column
                            prop="intoStatus"
                            label="入住状态"
                            align="center"
                            width="100">
                            <template slot-scope="scope">
                                {{scope.row.intoStatus === 1 ? '已入住' : '已退宿' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="depositItem"
                            align="center"
                            label="押金项目">
                        </el-table-column>
                        <el-table-column
                            prop="depositStandard"
                            align="center"
                            label="押金标准">
                        </el-table-column>
                        <el-table-column
                            prop="roomCount"
                            align="center"
                            label="宿舍数量">
                        </el-table-column>
                        <el-table-column
                            prop="money"
                            align="center"
                            label="金额">
                        </el-table-column>
                        <el-table-column
                            prop="refundMoney"
                            align="center"
                            label="退回金额">
                        </el-table-column>
                        <el-table-column
                            prop="refundMode"
                            align="center"
                            label="退回方式">
                        </el-table-column>
                        <el-table-column
                            prop="refundDate"
                            align="center"
                            label="退回日期">
                        </el-table-column>
                        <el-table-column
                            prop="executor"
                            align="center"
                            label="操作人">
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="220">
                            <template slot-scope="scope">
                                <el-button
                                    v-if="scope.row.refundStatus !== 1"
                                    size="mini"
                                    type="primary"
                                    @click="handleExport(scope.$index, scope.row)">线下退回</el-button>
                                <el-button
                                    v-if="scope.row.refundStatus === 1"
                                    size="mini"
                                    type="primary"
                                    @click="handleExport(scope.$index, scope.row)">押金撤回</el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleExport(scope.$index, scope.row)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @curDepositRefund-change="handleCurDepositRefundChange"
                        :background="true"
                        :curDepositRefund-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                title="详情"
                v-if="detailDialog.create"
                :visible.sync="detailDialog.visible"
                :close-on-click-modal="false"
                @closed="handleDetailClosed"
                class="custom-dialog big-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <DepositRefundDetail :params="detailDialog.params"></DepositRefundDetail>
            </el-dialog>

            <el-dialog
                title="添加"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog big-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <DepositRefundAdd @done="handleAddDone"></DepositRefundAdd>
            </el-dialog>
            
            <el-dialog
                title="编辑"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog big-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <DepositRefundEdit :params="editDialog.params" @done="handleEditDone"></DepositRefundEdit>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import DepositRefundDetail from './DepositRefundDetail'
import DepositRefundAdd from './DepositRefundAdd'
import DepositRefundEdit from './DepositRefundEdit'

export default {
    name: 'DemoDepositRefund',
    components: {
        DepositRefundDetail,
        DepositRefundAdd,
        DepositRefundEdit
    },
    data() {
        return {
            filters: {
                subcontractor: '',
                refaundStatus: '',
                intoStatus: '',
                refaundDate: '',
            },

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,

            detailDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
    },
    methods: {
        ...common,
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get(http.MOCK_BASE_API_URL + 'v1/cost/deposit/refund/pageList', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.list;
                    this.totalNum = req.data.totalCount;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurDepositRefundChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 详情弹窗口
        handleDetail(index, row) {
            this.detailDialog = { create: true, params: row };
            this.$nextTick(() => {
                this.detailDialog = { ...this.detailDialog, visible: true };
            });
        },
        handleDetailClosed() {
            this.detailDialog = { create: false, visible: false, params: {} };
        },

        // 添加弹窗口
        handleAdd() {
            this.addDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true };
            });
        },
        handleAddDone() {
            this.addDialog = { visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = {  create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        handleDisable(index, row, status) {
            http.put(http.MOCK_DepositRefund_API_URL + 'demo/disable', { id: row.id, status: status }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.loadTable();
                    this.showSuccess('禁用成功！');
                } else {
                    this.showError(req.data.msg);
                }
            });
        },
        handleDelete(index, row) {
            this.confirm('确认删除？', function () {
                http.delete(http.MOCK_DepositRefund_API_URL + 'demo/' + row.id).then(req => {
                    if (req.data.code == http.SUCCESS) {
                        this.loadTable();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },
        handleDeleteAll() {
            if (this.$refs.curDepositRefundTable.selection.length == 0) {
                this.showError('请选择要删除的信息！');
            } else {
                this.confirm('确认删除所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.curDepositRefundTable.selection.length; i++) {
                        idarr.push(this.$refs.curDepositRefundTable.selection[i].id);
                    }
                    http.delete(http.MOCK_DepositRefund_API_URL + 'demo', { data: idarr }).then(req => {
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
        handleExport() {}
    }
}
</script>

<style scoped>
</style>
